<template>
  <section
    class="
      shadow
      bg-white
      py-6
      px-10
      flex flex-col
      justify-center
      text-center
      items-center
      w-full
      sm:w-2/5
      xl:w-1/4
      card
    "
  >
    <div>
      <pet-icon
        :width="'w-14 sm:w-16'"
        :height="'h-14 sm:w-16'"
        class="text-gray-300"
      ></pet-icon>
    </div>
    <div class="space-y-2">
      <div class="">
        <h1 class="font-medium text-lg lg:text-xl">
          {{ pet.pet_info ? pet.pet_info.pet_name : '' }}
        </h1>
        <p class="text-gray-500 font-medium">
          <span class="text-gray-700"
            >{{ pet.pet_info ? pet.pet_info.pet_type : '' }}, </span
          ><span>{{ pet.pet_info ? pet.pet_info.pet_breed : '' }}</span>
        </p>
      </div>
      <div class="space-y-1">
        <h1 class="font-medium">
          {{ pet.client_details ? pet.client_details.name : '' }}
        </h1>
      </div>
    </div>
    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import petIcon from '../../Icons/petIcon.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'
export default {
  components: { petIcon, LoadingModel },
  props: {
    pet_id: {
      type: String
    }
  },

  created() {
    this.$store.dispatch('reports/getPet', this.pet_id)
  },

  computed: mapState('reports', ['pet', 'loading'])
}
</script>

<style></style>
