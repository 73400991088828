<template>
  <main>
    <section>
      <span
        @click="backToReport"
        class="flex space-x-2 text-primary mb-6 cursor-pointer"
      >
        <arrowback-icon></arrowback-icon>
        <span>back</span>
      </span>
    </section>

    <section v-if="report_history.length >= 1">
      <report-pet-profile :pet_id="pet_id"></report-pet-profile>
    </section>

    <div v-if="report_history.length < 1" class="mt-24 lg:mt-20 xl:mt-20">
      <div
        class="
          flex
          justify-center
          align-middle
          text-center text-lg
          md:text-xl
          xl:text-2xl
          font-medium
          -mb-6
          xl:-mb-14
        "
      >
        This pet doesn't have any report history
      </div>
      <div class="sm:w-1/2 md:w-3/5 mx-auto">
        <not-available></not-available>
      </div>
    </div>

    <section class="mt-14 lg:text-lg" v-if="report_history.length >= 1">
      <h3>Pet Report History</h3>

      <section
        class="
          mt-8
          py-2
          border-b border-solid border-gray-200
          w-full
          md:w-5/6
          lg:w-3/6
        "
      >
        <div
          class="text-base px-4 lg:px-6 pb-1 grid grid-cols-6 md:grid-cols-8"
        >
          <div class="col-span-5 md:col-span-7">
            <p>Date</p>
          </div>
          <div class="col-span-1">
            <p>Action</p>
          </div>
        </div>

        <div class="space-y-2">
          <div
            class="bg-white rounded even:bg-gray-50"
            v-for="(report, index) in report_history"
            :key="index"
          >
            <div
              class="
                grid grid-cols-6
                md:grid-cols-8
                text-sm
                lg:text-base
                text-gray-700
                cursor-pointer
                py-4
                px-4
                lg:px-6
              "
            >
              <div class="col-span-5 md:col-span-7">
                <div class="flex">
                  <p class="mr-3">{{ index + 1 }}.</p>
                  <date-created :created_at="report.updatedAt"></date-created>
                </div>
              </div>

              <div class="col-span-1">
                <div class="flex items-center justify-between">
                  <div @click="viewReport(report.id)" class="has-tooltip">
                    <view-icon
                      :width="'w-4  lg:w-5'"
                      :height="'h-4 lg:h-5'"
                    ></view-icon>
                    <span
                      class="
                        tooltip
                        bg-gray-500
                        text-white
                        py-1
                        px-1
                        md:px-2
                        rounded-md
                        shadow
                        text-xs
                        md:text-sm
                        opacity-60
                      "
                      >view</span
                    >
                  </div>

                  <div @click="editReport(report.id)" class="edit-has-tooltip">
                    <edit-icon
                      :width="'w-4 lg:w-5'"
                      :height="'h-4 lg:h-5'"
                    ></edit-icon>
                    <span
                      class="
                        edit-tooltip
                        bg-gray-500
                        text-white
                        py-1
                        px-1
                        md:px-2
                        rounded-md
                        shadow
                        text-xs
                        md:text-sm
                        opacity-60
                      "
                      >edit</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
// import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import arrowbackIcon from '../Icons/arrowbackIcon.vue'
import EditIcon from '../Icons/editIcon.vue'
import NotAvailable from '../Icons/notAvailable.vue'
import ViewIcon from '../Icons/viewIcon.vue'
import ReportPetProfile from '../Reports/Pet/ReportPetProfile.vue'
import DateCreated from '../Utilities/DateCreated.vue'
export default {
  components: {
    arrowbackIcon,
    ReportPetProfile,
    ViewIcon,
    EditIcon,
    DateCreated,
    NotAvailable
  },

  props: {
    pet_id: String
  },

  data() {
    return {
      id: null,
      view_index: 0,
      edit_index: 1
    }
  },

  watch: {
    pet() {
      if (this.pet != undefined) {
        this.id = this.pet.client_id
      }
    }
  },

  created() {},

  computed: {
    ...mapState('reports', ['pet', 'loading']),
    ...mapState('reports_history', ['report_history', 'loading'])
  },

  methods: {
    backToReport() {
      this.$router.push({
        name: 'Reports_Client_Profile',
        params: { client_id: this.id }
      })
    },

    viewReport(val) {
      this.$router.push({
        name: 'AddReport',
        params: { pet_id: this.pet_id, report_id: val, index: this.view_index }
      })
    },

    editReport(val) {
      var value = {
        pet_id: this.pet_id,
        report_id: val,
        index: this.edit_index
      }

      this.$store.dispatch('reports_history/editReportHistory', value)
    }
  },

  mounted() {
    if (this.pet != undefined) {
      this.id = this.pet.client_id
    }

    this.$store.dispatch('reports/getPet', this.pet_id)
    this.$store.dispatch('reports_history/getPetReportHistory', this.pet_id)
  }
}
</script>

<style scoped>
.tooltip {
  @apply invisible;
  @apply absolute;
  @apply -top-7;
  @apply -right-3;
}

.edit-tooltip {
  @apply invisible;
  @apply absolute;
  @apply -top-7;
  @apply -left-2;
}

.has-tooltip,
.edit-has-tooltip {
  @apply relative;
}

.has-tooltip:hover .tooltip,
.edit-has-tooltip:hover .edit-tooltip {
  @apply visible;
  @apply z-50;
}
</style>
